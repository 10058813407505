
// Adjust the sidebars width when this nicklist is in view
.kiwi-container .kiwi-sidebar.kiwi-sidebar-section-nicklist {
    width: 250px;
    max-width: 300px;

    @media screen and (max-width: 769px) {
        width: 100%;
        max-width: 300px;
    }
}

.kiwi-nicklist {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.kiwi-nicklist-filter {
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 100% 0;
    grid-template-columns: max-content auto max-content;
    align-items: center;
    width: 100%;
    cursor: pointer;
    min-height: 42px;
    padding: 0 12px;
    border-bottom: 1px solid;

    > div {
        overflow: hidden;
        font-weight: 600;
        white-space: nowrap;
    }

    .kiwi-nicklist-usercount {
        grid-column: span 2;

        .kiwi-nicklist--filtering & {
            grid-column: span 1;
        }
    }

    input {
        box-sizing: border-box;
        display: none;
        width: 100%;
        height: 100%;
        padding: 0 8px;
        font-weight: normal;
        background: none;
        border: none;
        outline: 0;

        .kiwi-nicklist--filtering & {
            display: block;
        }
    }

    .fa-search {
        font-size: 1.2em;
        opacity: 0.3;
        transition: opacity 0.3s;

        &:hover,
        .kiwi-nicklist--filtering & {
            opacity: 1;
        }
    }

    .kiwi-nicklist-usercount-width {
        height: 0;
        visibility: hidden;
    }
}

.kiwi-nicklist-users {
    flex: 1;
    width: 100%;
    padding: 4px 0;
    overflow: hidden scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--comp-scroller-fg, #cdcdcd) var(--comp-scroller-bg, #f0f0f0);

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--comp-scroller-fg, #cdcdcd);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--comp-scroller-bg, #f0f0f0);
    }
}
