
.kiwi-sidebar.kiwi-sidebar-section-user {
    right: 0;
    width: 380px;
}

.kiwi-userbox {
    box-sizing: border-box;
    overflow-y: auto;
    height: 100%;
}

.kiwi-userbox-selfprofile {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 1em;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
}

.kiwi-userbox-header {
    position: relative;
    padding: 0.5em;
    box-sizing: border-box;
    display: flex;
}

.kiwi-userbox-avatar {
    position: relative;
    margin: 1em;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
}

.kiwi-userbox-userinfo {
    box-sizing: border-box;
    margin-top: 1.2em;
    flex-grow: 1;
    word-break: break-all;
}

.kiwi-userbox-nick {
    font-weight: 800;
    font-size: 1.4em;
}

.kiwi-userbox-modestring {
    font-weight: normal;
    font-size: 0.8em;
    margin-left: 6px;
}

.kiwi-userbox-usermask {
    display: block;
    opacity: 0.6;
    cursor: default;
    padding-left: 1px;
}

.fa-user.kiwi-userbox-icon {
    display: inline-block;
    font-size: 2em;
}

.kiwi-userbox-basicinfo {
    width: 100%;
    display: block;
    padding: 0 1.5em 0.5em 1.5em;
    box-sizing: border-box;
}

.kiwi-userbox-basicinfo-title,
.kiwi-userbox-basicinfo-data {
    display: block;
    width: 100%;
    cursor: default;
    margin: 0;
}

.kiwi-userbox-basicinfo-title {
    font-size: 1em;
    line-height: 1em;
    padding: 0;
    font-weight: 900;
}

.kiwi-userbox-basicinfo-data {
    font-weight: normal;
    opacity: 1;
}

.kiwi-userbox-actions {
    width: 100%;
    padding: 0.5em;
    text-align: center;
    user-select: none;
    box-sizing: border-box;

    /* using display flex here to prevent spaces making things uneven */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .kiwi-userbox-action {
        border: 1px solid;
        padding: 0.5em 1em;
        cursor: pointer;
        margin: 0.5em;
        transition: all 0.3s;
        border-radius: 3px;
    }

    .kiwi-userbox-action:empty {
        display: none;
    }

    label {
        display: block;
        cursor: pointer;

        span {
            text-align: left;
            width: auto;
        }
    }
}

.kiwi-userbox-plugin-actions {
    padding: 0;
}

.kiwi-userbox-opactions {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 0 0 1em 0;
    border-top: 1px solid;
    padding: 1em 1.5em;
}

.kiwi-userbox-opactions label {
    width: 100%;
    font-size: 1.2em;
    font-weight: 600;
    display: block;
    margin-bottom: 0.7em;
}

.kiwi-userbox-opactions label select {
    display: block;
    clear: both;
    padding: 10px;
    border-radius: 0.25em;
    box-shadow: none;
    border: 1px solid;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
}

.kiwi-userbox-opaction {
    width: 100%;
    padding: 0 1em;
    text-align: left;
    border: none;
    line-height: 2.2em;
    font-size: 0.8em;
}

.kiwi-userbox-opaction i {
    margin-right: 0.2em;
    font-size: 1.2em;
}

.kiwi-userbox-query-invite select {
    margin-right: 10px;
}

.kiwi-userbox-whois {
    line-height: 1.4em;
    padding: 1em;
    margin: 0 1em 1em 1em;
    background: none;
    box-sizing: border-box;
    border-radius: 1em;
}

.kiwi-userbox-whois-line {
    display: block;
}

.kiwi-userbox-whois--loading {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
}

.kiwi-userbox-ignoreuser {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.kiwi-userbox-ignoreuser label {
    margin: 0 0 1em 0;
}

.kiwi-userbox-ignoreuser span {
    /* This fixes a vertical align issue between the checkbox and span */
    float: right;
}

@media screen and (max-width: 769px) {
    .kiwi-container--sidebar-drawn .kiwi-sidebar-userbox {
        width: 100%;
    }

    .kiwi-userbox {
        left: 0;
        right: 0;
        bottom: 40px;
        top: auto;
        max-width: 100%;
        border-width: 1px 0;
    }

    .kiwi-userbox .kiwi-userbox-header i {
        display: none;
    }

    .kiwi-userbox-actions {
        width: 100%;
        box-sizing: border-box;
    }

    .kiwi-userbox-plugin-actions {
        padding: 0;
    }

    .kiwi-userbox-actions .kiwi-userbox-action {
        width: 66%;
        clear: both;
        display: block;
    }
}

#iframe-wrapper-userinfo {
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

@media (min-height: 694.1px) and (max-height: 740px) {
    #iframe-wrapper-userinfo {
        bottom: -20px;
    }

    #iframe-wrapper-userinfo iframe {
        transform: scale(0.875); /* 100/115 */
    }
}

@media (min-height: 674.1px) and (max-height: 694px) {
    #iframe-wrapper-userinfo {
        bottom: -32px;
    }

    #iframe-wrapper-userinfo iframe {
        transform: scale(0.8); /* 100/125 */
    }
}

@media (min-height: 654.1px) and (max-height: 674px) {
    #iframe-wrapper-userinfo {
        bottom: -44px;
    }

    #iframe-wrapper-userinfo iframe {
        transform: scale(0.725); /* 100/133.333 */
    }
}

@media (max-height: 654px) {
    #iframe-wrapper-userinfo {
        bottom: -56px;
    }

    #iframe-wrapper-userinfo iframe {
        transform: scale(0.65); /* 100/153.846 */
    }
}
